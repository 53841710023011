import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const listVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.3,
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

export default function Profile() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section
      id="profile"
      ref={ref}
      className="min-h-screen flex items-center justify-center bg-gray-800 text-white pt-8 pb-8 w-full px-4 sm:px-6 md:px-8"
    >
      <div className="w-full max-w-7xl container mx-auto p-5 md:p-20 flex flex-col items-start space-y-6 font-ntr">
        <h2 className="text-4xl mb-10 sm:mb-5 font-bold text-left w-full md:w-auto">
          <span className="text-custom-accent">/ </span>About Me
        </h2>
        <div className="flex flex-col md:flex-row items-start justify-center w-full space-y-6 md:space-y-0 md:space-x-6">
          {/* Left side - About Me */}
          <div className="md:w-4/5 space-y-4 text-left">
            <p className="text-lg sm:text-lg lg:text-xl mb-4 leading-relaxed text-left text-white font-light">
              I am currently a software developer at{" "}
              <a
                href="https://chainxy.com/pages/about"
                className="text-custom-accent"
                target="_blank"
                rel="noopener noreferrer"
              >
                ChainXY
              </a>
              , working within the data extraction team. I have a passion for
              creating innovative software solutions and continuously improving
              my skills.
            </p>
            <h2 className="text-lg text-left">
              Here are some technologies I have been working with:
            </h2>
            <ul className="grid grid-cols-2 gap-x-2 gap-y-2 list-inside">
              {[
                "JavaScript ES6+",
                "React.js",
                "TypeScript",
                "Python",
                "C#",
                ".Net",
              ].map((tech, index) => (
                <motion.li
                  key={tech}
                  className="flex items-center space-x-1 text-left"
                  custom={index}
                  initial="hidden"
                  animate={controls}
                  variants={listVariants}
                >
                  <span className="text-custom-accent">→</span>
                  <span>{tech}</span>
                </motion.li>
              ))}
            </ul>
            <p className="text-lg sm:text-lg lg:text-xl mb-4 leading-relaxed text-left text-white font-light">
              Outside of work, I am passionate about maintaining an active and
              healthy lifestyle. I enjoy playing various sports.
            </p>
          </div>

          {/* Right side - Image Card */}
          <div className="hidden md:flex md:w-2/5 justify-center">
            <div className="w-full max-w-xs bg-white text-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform duration-50 ease-in-out transform hover:-translate-y-2">
              <img
                src="/me.jpg"
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
