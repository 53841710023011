import React, { useState, useEffect } from "react";
import { projects } from "../data";
import ProjectCard from "./ProjectCard";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const projectVariants = {
  hidden: { opacity: 0 },
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
      duration: 1,
      ease: "easeInOut",
    },
  }),
};

export default function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.section
      id="projects"
      ref={ref}
      initial="hidden"
      animate={controls}
      className="flex items-center justify-center bg-gray-800 text-white pt-8 pb-16 w-full px-4 sm:px-6 md:px-8"
    >
      <div className="w-full max-w-7xl container mx-auto p-5 md:py-10 md:px-20 flex flex-col items-start space-y-6 font-ntr">
        <div className="flex flex-col w-full mb-4 text-left">
          <h2 className="text-4xl mb-10 sm:mb-5 font-bold text-left w-full md:w-auto">
            <span className="text-custom-accent">/ </span>Projects
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <motion.div
              key={project.image}
              className="bg-gray-600 bg-opacity-50 p-6 rounded-lg shadow-lg cursor-pointer transition-transform transform hover:scale-105 hover:bg-opacity-75"
              custom={index}
              variants={projectVariants}
              onClick={() => setSelectedProject(project)}
            >
              <h2 className="text-custom-accent text-sm font-medium mb-1">
                {project.subtitle}
              </h2>
              <h1 className="text-white text-lg font-medium mb-3">
                {project.title}
              </h1>
              <img
                alt="gallery"
                className="object-contain object-center rounded-lg w-full h-48"
                src={project.image}
              />
            </motion.div>
          ))}
        </div>
        {selectedProject && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="w-11/12 md:w-2/3 lg:w-1/2">
              <ProjectCard
                project={selectedProject}
                onBack={() => setSelectedProject(null)}
              />
            </div>
          </div>
        )}
      </div>
    </motion.section>
  );
}
