import React, { useState, useEffect } from "react";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Profile from "./components/Profile";
import Projects from "./components/Projects";

export default function App() {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPosition = window.scrollY || window.pageYOffset;
    const scrollPercentage = Math.min(100, Math.max(0, (scrollPosition / totalHeight) * 100));

    setScrollProgress(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="text-black-400 bg-white-900 body-font w-full overflow-x-hidden pt-16">
      <Navbar />
      <div
        className="h-1.5 bg-[#64ffda] fixed top-16 left-0 right-0 z-40 block"
        style={{ width: `${scrollProgress}%` }}
      ></div>
      <About />
      <Profile />
      <Projects />
      <footer className="w-full text-center py-12 bg-gray-800 text-white text-xs px-4 sm:px-6 md:px-8">
        Built and designed by Connor Flynn.
        <br />
        All rights reserved. ©
      </footer>
    </main>
  );
}
